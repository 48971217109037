// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/opt/buildhome/repo/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("/opt/buildhome/repo/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("/opt/buildhome/repo/src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-brands-tsx": () => import("/opt/buildhome/repo/src/pages/brands.tsx" /* webpackChunkName: "component---src-pages-brands-tsx" */),
  "component---src-pages-contact-tsx": () => import("/opt/buildhome/repo/src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-dropship-tsx": () => import("/opt/buildhome/repo/src/pages/dropship.tsx" /* webpackChunkName: "component---src-pages-dropship-tsx" */),
  "component---src-pages-index-tsx": () => import("/opt/buildhome/repo/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-jobs-tsx": () => import("/opt/buildhome/repo/src/pages/jobs.tsx" /* webpackChunkName: "component---src-pages-jobs-tsx" */),
  "component---src-pages-make-payment-tsx": () => import("/opt/buildhome/repo/src/pages/make-payment.tsx" /* webpackChunkName: "component---src-pages-make-payment-tsx" */),
  "component---src-pages-open-tsx": () => import("/opt/buildhome/repo/src/pages/open.tsx" /* webpackChunkName: "component---src-pages-open-tsx" */),
  "component---src-pages-platform-partners-tsx": () => import("/opt/buildhome/repo/src/pages/platform-partners.tsx" /* webpackChunkName: "component---src-pages-platform-partners-tsx" */),
  "component---src-pages-policies-privacy-tsx": () => import("/opt/buildhome/repo/src/pages/policies/privacy.tsx" /* webpackChunkName: "component---src-pages-policies-privacy-tsx" */),
  "component---src-pages-policies-terms-of-service-tsx": () => import("/opt/buildhome/repo/src/pages/policies/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-policies-terms-of-service-tsx" */),
  "component---src-pages-services-thunderbolt-tsx": () => import("/opt/buildhome/repo/src/pages/services/thunderbolt.tsx" /* webpackChunkName: "component---src-pages-services-thunderbolt-tsx" */),
  "component---src-pages-test-tsx": () => import("/opt/buildhome/repo/src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */),
  "component---src-pages-tracking-tsx": () => import("/opt/buildhome/repo/src/pages/tracking.tsx" /* webpackChunkName: "component---src-pages-tracking-tsx" */)
}

